<template>
  <div class="routeUpdate-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      exportMethod="delay"
    >
      <template #headerSlot>
        <v-button text="新增路线" permission="add" @click="createRoute"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="路线名称" v-model="searchParams.name"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="打点规则" v-model="searchParams.rule" :options="ruleOps"></v-select>
        <v-select label="巡更类型" v-model="searchParams.patrolMethod" :options="patrolPointTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="路线有效期" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="editRoute(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="remove(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, deleteURL } from './api'
import { ruleOps, statusOps, ruleMap, statusMap, communityParams, patrolPointTypeOps, patrolPointTypeMap } from './map'
export default {
  name: 'routeUpdate',
  computed: {
    videoPermission () {
      const permission = this.$store.getters.getPermission('videoControl')
      if (!permission) {
        this.searchParams.patrolMethod = 1 
      }
      return permission
    },
    patrolPointTypeOps () {
      return patrolPointTypeOps.call(this, 2)
    },
    patrolPointTypeMap () {
      return patrolPointTypeMap.call(this)
    }
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        name: '',
        communityId: '',
        rule: null,
        status: null,
        startTime: '',
        endTime: '',
        patrolMethod: undefined
      },
      ruleOps: ruleOps,
      statusOps: statusOps,
      communityParams: communityParams,
      headers: [
        {
          prop: 'name',
          label: '路线名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'patrolMethod',
          label: '巡更类型',
          formatter: (row) => {
            return this.patrolPointTypeMap[row.patrolMethod] || ''
            // return row.patrolMethod === 2 ? '视频远程巡更' : '线下巡更'
          }
        },
        {
          prop: 'pointCount',
          label: '关联巡更点',
          align: 'right'
        },
        {
          prop: 'rule',
          label: '打点规则',
          formatter: (row) => ruleMap[row.rule]
        },
        {
          prop: 'frequencyCount',
          label: '每日巡更次数',
          align: 'right'
        },
        {
          prop: 'beginDate',
          label: '路线有效期',
          formatter: (row) => row.beginDate + '~' + row.endDate
        },
        {
          prop: 'status',
          label: '状态',
          formatter: (row) => statusMap[row.status]
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    createRoute () {
      this.$router.push({
        name: 'pathUpdateForm',
        query: {
          type: this.searchParams.patrolMethod
        }
      })
    },
    editRoute (row) {
      this.$router.push({
        name: 'pathUpdateForm',
        query: {
          id: row.id
        }
      })
    },
    async remove (row) {
      let isConfirm = await this.$confirm('是否删除此条巡更路线？')
      if (isConfirm) {
        let { status } = await this.$axios.delete(`${deleteURL}/${row.id}`)
        if (status - 0 === 100) {
          this.$refs.list.searchData()
        }
      }
    }
  }
}
</script>
